<template>
    <admin-dashboard-layout>
        <template v-slot:left-tools>
            <timeframe-select/>
        </template>
        <template v-slot:right-tools>
            <v-btn @click="$router.push({name: 'admin.videos.create'})" v-text="'Create'"/>
        </template>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="videos"
            :items-per-page="5"
            class="elevation-1"
            @click:row="handleClickRow">
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at  | formattedDate }}
            </template>
            <template v-slot:item.tags="{ item }">
               <v-chip x-small  v-for="tag in item.tags" :key="`tag-`+tag.id">
                   {{ tag.name }}
               </v-chip>
            </template>
            <template v-slot:item.lang="{ item }">
                <v-chip x-small>
                    {{ item.language }}
                </v-chip>
            </template>
            <template v-slot:item.description="{ item }">
               <span v-if="item.description">
                   {{item.description.substr(0, 20)}}...
               </span>
            </template>
            <template v-slot:item.actions="{ item }">
                <delete-dialog :model="item" type="videos" @success="init" />
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import Video from "../../../models/Video";
    import DeleteDialog from "@/components/dialogs/DeleteDialog";
    import TimeframeSelect from "@/views/partials/TimeframeSelect";

    export default {
        name: "users-index",
        components: {TimeframeSelect, DeleteDialog, AdminDashboardLayout},
        data: function () {
            return {
                loading: false,
                videos: [],
                headers: [
                    {
                        text: '# ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    { text: 'Title', value: 'name' },
                    { text: 'Description', value: 'description' },
                    { text: 'Hashtags', value: 'tags' },
                    { text: '# Downloads', value: 'downloads_count' },
                    { text: '# Shares', value: 'shares_count' },
                    { text: 'Rank', value: 'rank' },
                    { text: 'Type', value: 'type' },
                    { text: 'Language', value: 'lang' },
                    { text: 'Notes', value: 'notes' },
                    { text: 'Created at', value: 'created_at' },
                    { text: 'File size', value: 'file_size' },
                    { text: 'Actions', value: 'actions' },
                ],
            }
        },
        methods: {
            handleClickRow(video) {
                this.$router.push({name: 'admin.videos.show', params: {id: video.id}})
            },
            async init() {
                this.loading = true
                await this.loadTable();
                this.loading = false
            },

            async loadTable() {
                const query = Video.include(['tags']);

                if (this.$route.query.lastDays) {
                    query.where('lastDays', this.$route.query.lastDays);
                }

                this.videos = await query.get()
            }
        },
        mounted() {
          this.init()
        },
        watch: {
            "$route.query": async function (val, oldVal) {
                if (val !== oldVal) {
                    await this.init()
                }
            }
        }
    }
</script>

<style scoped>

</style>
